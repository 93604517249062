#all-patients-tbl {
    width: 90%;
    margin: auto;

    h4 {
        margin: 5rem auto 2.5rem auto;
    }

    th {
        span {
            margin-left: 0.4rem;
        }
    }
}
