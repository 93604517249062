@import "../../../styles/_colors.scss";

.mail-response {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0, 0);
    justify-content: center;
    align-items: center;

    &-text {
        p {
            font-size: 30px;
            font-weight: 600;
            text-align: center;
        }
    }

    &-img {
        width: 20%;
    }

    &-btn {
        color: $eupatiWhiteColor;
        border-radius: 41px;
        border-color: transparent;
        width: 20%;
        padding: 1%;
        background-color: $secondaryColor;
        margin: 30px auto;
        display: block;
        font-weight: 600;
        font-size: 22px;

        &:hover {
            background-color: $navBtnsBgColor;
        }
    }
}
