@import "../../../styles/common";
@import "../../../styles/colors";

table {
    text-align: center;
}

.arrows-menu {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.left-arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-icon {
        font-size: 35px;
        color: $secondaryColor;
    }

    &-text {
        &:hover {
            font-weight: bolder;
        }
    }
}

.right-arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-icon {
        font-size: 35px;
        color: $mainColor;
    }

    button {
        color: $eupatiWhiteColor;
    }

    &-text {
        &:hover {
            font-weight: 500;
        }
    }
}

.select-dropdown {
    div > svg {
        color: #808080;

        &:hover {
            color: #fff;
        }
    }
}

.navbar-bottom-menu {
    &-list {
        & li:last-child {
            margin-right: auto;
        }

        & > li {
            margin-right: 1rem;

            & > a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.footer {
    width: 100%;

    &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        height: 100px;

        &-txt {
            display: flex;
            position: relative;
            top: 0%;
            left: 0%;
            transform: translate(0, 0);
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &-title {
                color: $darkBlue;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
            }
        }

        // .navbar-nav {
        //     .nav-link {
        //         color: white;
        //         padding-left: 0;

        //         &:hover {
        //             color: $secondaryColor;
        //         }
        //     }
        // }

        // &-button {
        //     width: 100%;
        //     font-size: 2rem;
        //     font-weight: bold;
        // }
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        background-color: $darkBlue;

        & > span {
            color: $eupatiWhiteColor;
        }

        .navbar-nav {
            .nav-item {
                & > a {
                    color: $eupatiWhiteColor;
                    padding-right: 0;

                    &:hover {
                        color: $secondaryColor;
                    }
                }
            }
        }
    }
}

.confirm-page {
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & > h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: center;
        }

        & > li,
        a {
            text-decoration: none;
            list-style: none;
        }
    }

    &-btn {
        text-align: center;
        color: #fff;
        background-color: #00749f;
        padding: 7px 22px 7px;
        border: 0;
        border-radius: 30px;
        border-radius: 30px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            background-color: $secondaryColor;
        }
    }
}

.rich-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4.5rem;

    &-toolbar {
        position: sticky;
        top: 0;
        z-index: 2;
        border-color: $darkBlue !important;
        background-color: $mainColor !important;
        margin-bottom: 1rem !important;
    }

    &-wrapper {
        width: 60%;
    }

    &-editor {
        min-height: 14em !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        border-radius: 2px !important;
        border-color: $darkBlue !important;
        margin: auto auto 1rem auto;
    }

    &-textarea {
        min-width: 60%;
        min-height: 14em;
        margin-bottom: 7rem;
        padding: 5px;
        border: 1px solid #f1f1f1;
        border-radius: 2px;
        border-color: $darkBlue;
    }
}

.tox-editor-header {
    position: sticky;
    top: 0;
    z-index: 2;
}

#editor-btns {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

#gotoLastPageBtn,
#backToFirstPageBtn {
    background-color: transparent;
    color: black;
    border-color: $navBtnsBgColor;

    &:hover {
        background-color: $navBtnsBgColor;
        color: white;
    }
}

.editor-option-custom {
    padding: 20px !important;
    width: 15px !important;
    height: 15px !important;
    border-color: $darkBlue !important;
    color: $darkBlue;

    &:hover {
        background-color: #d3d3d3 !important;
    }
}

.editor-option-custom-wide,
.editor-option-custom-medium {
    min-height: 40px;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.btn {
    border-radius: 42px;
}

.popup-content.modal-content {
    padding: 0;
    margin: 0;
    max-width: 55%;
    border: transparent;
}

.popup-content.modal-content.max-size-content {
    min-width: 80%;
}

.modal {
    &-recipients {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 1rem 1rem 0rem 1rem;
    }

    &-subject {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0 1rem 0.5rem 1rem;
    }

    &-templates {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0.5rem 1rem 1rem 1rem;
    }

    &-content {
        margin: 0;
        min-height: auto;
        overflow: auto;

        &-wrapper {
            overflow: auto;
            max-height: 630px;
        }

        #content-editor {
            height: 18.5rem;
        }

        textarea {
            width: 100%;
            height: auto;
        }
    }

    &-header {
        align-items: center;
        background-color: #404040;
        margin: 0;

        &-icons {
            display: flex;
            min-width: 20%;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
            cursor: pointer;

            &-min,
            &-max,
            &-ext {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 33%;
                min-height: 33px;

                &:hover {
                    background-color: #737373;

                    svg {
                        color: #fff;
                    }
                }
            }

            &-min {
                align-items: self-start;

                svg {
                    font-size: 17px;
                }
            }

            svg {
                font-size: 20px;
                margin: 0 auto;
                padding: 0;
                cursor: pointer;
                color: #737373;
            }
        }

        span {
            color: #fff;
        }
    }

    &-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        padding: 0 1rem 1rem 1rem;
    }

    &-close {
        position: relative;
        display: block;
        float: right;
        padding: 2px 5px;
        line-height: 20px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 0px solid #cfcece;

        &:hover {
            background-color: #c82333;
            color: #fff;
        }
    }

    &-actions {
        display: flex;
        width: 100%;
        padding: 10px 5px;
        margin: auto;
        text-align: center;
        justify-content: space-around;
    }

    &-core {
        width: 100%;
        padding: 25px 25px;
        margin: 0 auto;
        text-align: center;
        word-break: break-word;
    }

    &-head {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
        font-weight: bold;
    }
}

.selected-row {
    background-color: #daeef2;
}

.table-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem auto 0.6rem auto;
}

#copy-emails-btn {
    margin-bottom: 10px;
}

#line {
    margin-bottom: 0.25rem;
}

#template-triggers-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

#receivers {
    svg {
        color: lightgray;
    }

    label {
        display: flex;
    }

    .form {
        &-label {
            font-weight: 500;
        }

        &-group {
            select {
                width: 100%;
            }
        }
    }

    .btn {
        &-primary {
            background-color: transparent;
            border-color: transparent;

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                background-color: transparent;
            }

            &.active {
                background-color: transparent;
                border-color: transparent;

                svg {
                    color: $mainColor;
                }
            }

            &.focus {
                background-color: transparent;
                border-color: transparent;
                box-shadow: none;
            }
        }
    }

    .active,
    .focus {
        background-color: transparent;
    }
}

#stripe-payment {
    width: 70%;
    margin: 0 auto;

    h4 {
        display: flex;
        justify-content: center;
        margin: 5rem auto 2.5rem auto;
    }

    button {
        display: flex;
        justify-content: center;
        align-content: center;
        margin: 0 auto;
        width: 150px;
    }

    #row-wrapper {
        display: flex;
        flex-wrap: wrap;

        #row {
            display: flex;
            flex: 1 0 21%;
            flex-direction: column;
            margin: 0 1rem 0 0;

            &:nth-child(4),
            &:nth-child(8) {
                margin: 0;
            }
        }
    }

    #row-wrapper-2 {
        display: flex;
        flex-wrap: wrap;

        #row-2 {
            display: flex;
            flex: 1 0 49%;
            flex-direction: column;
            margin: 0 1rem 0 0;

            &:nth-child(2) {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .navbar-bottom-menu {
        &-list {
            & li:first-child {
                margin-top: 1rem;
            }

            & li:last-child {
                margin-right: 1rem;
            }

            & > li {
                margin-bottom: 1rem;
                margin-right: 1rem;
            }
        }
    }

    #stripe-payment {
        width: auto;

        #row-wrapper {
            #row {
                display: flex;
                flex: 1 0 50%;
                flex-direction: column;
                margin: 0 1rem 0 1rem;

                &:nth-child(4),
                &:nth-child(8) {
                    margin: 0 1rem 0 1rem;
                }
            }
        }

        #row-wrapper-2 {
            #row-2 {
                margin: 0 1rem 0 1rem;

                &:nth-child(2) {
                    margin: 0 1rem 0 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 609px) {
    .footer {
        &-top {
            align-items: center;
        }

        &-bottom {
            flex-direction: column;
        }
    }

    .modal {
        &-actions {
            flex-direction: column;
        }
    }
}
