@import "./_colors.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 18px;
    scrollbar-width: thin;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Move reCAPTCHA v3 badge to the left */

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.4s ease !important;
    left: 1px !important;
}

.grecaptcha-badge:hover {
    width: 256px !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.footer {
    margin-top: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

textarea {
    resize: none;
}

.container {
    max-width: 100%;
    padding: 0;
}

input[type="date"] {
    width: auto;
}

.__react_component_tooltip {
    pointer-events: auto !important;
}

.tox.tox-tinymce-aux,
.tox-fullscreen .tox.tox-tinymce-aux {
    z-index: 4000;
}

// .tox-menu.tox-collection.tox-collection--list.tox-selected-menu {
//     position: absolute;
//     left: 670.55px;
//     top: 1% !important;
//     max-height: 390px;
//     overflow: hidden auto;
//     min-width: 56px;
// }

.loader-wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    margin: -25px 0 0 -25px;
}

.form-group {
    select {
        width: auto;
    }
}

.navbar-top {
    display: flex;
    min-height: 58px;
    margin: 0px;
    justify-content: space-between;
    background-color: $eupatiWhiteColor; // was $darkBlue
    padding-top: 0px;
    padding-bottom: 0px;

    &-btns {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-home {
        cursor: pointer;
        font-size: 1.5rem;
    }

    &-button {
        //border-radius: 30px;
        transition: box-shadow 0.4s;
        //border: 1px solid #ccc;
        background-color: $eupatiWhiteColor;
        font-weight: 500;
        // padding-top: 2.5rem;
        // padding-bottom: 2.5rem;

        &:hover {
            color: $mainColor;
            // background-color: $darkBlue;
            // color: $eupatiWhiteColor !important;
        }
    }

    &-brand {
        max-width: 10%;
        margin: 0;
        padding: 0;
        height: auto;
        float: right;
        //width: 250px;
        position: relative; // was absolute
        background-color: white;
        //left: 17px;
        //top: -18px;
        border-radius: 10px;
        //box-shadow: -12px 9px 16px 3px rgba(0, 0, 0, 0.2);
        //z-index: 2;

        &-logo {
            width: 90px;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

#homeLink {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0;
    color: $darkBlue;
    font-size: 16px;

    &:hover {
        color: $mainColor;
    }

    &>svg {
        margin: auto 7px 3.5px auto;
        width: 0.8em;
    }
}

#eupatiLink {
    color: $darkBlue;
    padding: 0;
    font-size: 16px;

    &:hover {
        color: $mainColor;
    }
}

.navbar-bottom {
    background-color: $darkBlue;
    margin: 0;
    padding: 0;

    &-menu {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0px 5px 0px;

        &.navbar-collapse.collapse {
            margin: 0;
        }

        &-list {
            justify-content: space-between;
            flex-direction: column;

            &-item {
                background-color: $navBtnsBgColor;
                font-weight: 500;
                border-radius: 30px;
                color: $eupatiWhiteColor;
                padding: 7px 22px 7px;

                &:hover {
                    background-color: $secondaryColor;

                    a {
                        color: $eupatiWhiteColor;
                    }
                }
            }
        }
    }

    .nav-item {
        text-align: center;
        color: $eupatiWhiteColor;
        background-color: $navBtnsBgColor;
        padding: 10px 22px 10px;
        margin-left: 0;
        border: 0;
        //border-radius: 30px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            background-color: $secondaryColor;
        }

        a {
            color: $eupatiWhiteColor;
            padding: 0;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
}

.auth-nav-items {
    display: flex;
    justify-content: flex-end;
}

.admin-nav-items {
    display: flex;

    .nav-item {
        background-color: $darkBlue;

        &:first-child {
            margin: 0px;
        }
    }
}

.ribbon {
    border-color: $secondaryColor;
    width: 100%;
    height: 3px;
    background-color: $secondaryColor;
    margin-top: 0;
    margin-bottom: 0px;
}

.cookieConsentBtn {
    background-color: $secondaryColor  !important;

    &:hover {
        color: $eupatiWhiteColor  !important;
        background-color: $mainColor  !important;
    }
}

.google-message {
    color: #d3d3d3;
    font-size: 70%;

    &-privacy,
    &-tos {
        color: #d3d3d3;

        &:hover {
            color: #d7d7d7;
        }
    }
}

/* width */
body::-webkit-scrollbar {
    width: 1rem;
}

/* Track */
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $mainColor;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: $darkBlue;
    border-radius: 1rem;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: $secondaryColor;
}

@media screen and (max-width: 767px) {

    .researcher-info,
    .project-info,
    .pt-info,
    .profile-info,
    .experience-info,
    .requirements-info {
        flex-direction: column;
    }

    .titles {
        width: 100%;
    }
}

@media screen and (max-width: 884px) {
    html {
        font-size: 16px;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    body::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge add Firefox */
    body {
        -ms-overflow-style: none;
        scrollbar-width: none;
        /* Firefox */
    }

    #homeLink {
        display: none;
    }
}

@media screen and (max-width: 580px) {
    .upper-sec {
        &-txt {
            &-title {
                font-size: 32px;
            }

            &-para {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 992px) {

    html,
    body {
        overflow-x: hidden !important;
    }

    .admin-nav-items {
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .nav-item {
            margin: 0;
        }
    }

    .auth-nav-items {
        justify-content: center;
        align-items: center;
    }

    .navbar-expand-lg .navbar-collapse {
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
    }
}