@import "../static.scss";
@import "../../../../styles/colors";

.manual {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    padding: 1em;

    h4 {
        text-align: center;
        margin-bottom: 50px;
    }

    &-info {
        display: flex;
        justify-content: space-between;

        .lcolumn,
        .rcolumn {
            width: 49%;
            display: flex;
            flex-direction: column;
            padding: 1em;
        }

        .lcolumn {
            & > div {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        }

        &-field {
            svg {
                color: lightgray;
            }

            .form {
                &-label {
                    font-weight: 500;
                }

                &-group {
                    select {
                        width: 100%;
                    }
                }
            }

            .btn {
                &-primary {
                    background-color: transparent;
                    border-color: transparent;

                    &:not(:disabled):not(.disabled):active,
                    &:not(:disabled):not(.disabled).active {
                        background-color: transparent;
                    }

                    &.active {
                        background-color: transparent;
                        border-color: transparent;

                        svg {
                            color: $mainColor;
                        }
                    }

                    &.focus {
                        background-color: transparent;
                        border-color: transparent;
                        box-shadow: none;
                    }
                }
            }

            .active,
            .focus {
                background-color: transparent;
            }
        }
    }

    #patientExpertises {
        flex-direction: column;
    }
}

#manual-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;

    & > button {
        border-radius: 42px;
        padding: 0.5rem;
        width: 7rem;
    }

    .sbmt {
        border: 0;
        background-color: $mainColor;

        &:hover {
            background-color: $secondaryColor;
        }
    }
}

@media screen and (max-width: 767px) {
    .manual {
        &-info {
            flex-direction: column;

            .lcolumn,
            .rcolumn {
                width: 100%;
            }
        }
    }
}
