@import "../../../../styles/colors";

#price-catalog-tbl {
    width: 90%;
    margin: auto;

    .table-hover tbody tr:hover {
        background-color: $mainColor;
        color: $eupatiWhiteColor;
        font-weight: bold;
    }

    button {
        text-align: center;
        color: #fff;
        background-color: #00749f;
        padding: 10px 40px 10px;
        border: 0;
        border-radius: 30px;
        font-weight: 500;
        cursor: pointer;
        margin: 4rem 2rem 2rem 2rem;

        &:hover {
            background-color: $secondaryColor;
        }
    }
}
