.email-templates-wrapper {
    width: 90%;
    margin: 0 auto;

    h4 {
        margin: 5rem auto 2.5rem auto;
    }

    #create-btn {
        margin-bottom: 1rem;
    }

    #email-template-editor-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .form-label {
        font-weight: bolder;
    }
}
