$mainColor: #00A4B6;
$mainColorLight: #DAEEF2;
$secondaryColor: #F0B72F;
$eupatiWhiteColor: #fff;
$darkBlue: #113D7C;
$darkBlueText: #183E78;
$lightGreen: #DAEEF2;

//loading animation
$circleColor: #1da1f2;
$quadrantColor: rgba(29, 161, 242, 0.2);

//btns
$navBtnsBgColor: #00749F;

//retired
$orange: #E5500C;
//$mainColor: #113D7C;
