@import "../../../styles/_colors.scss";

$forms: profile, experience, requirements;

@each $form in $forms {
    &.#{$form} {
        display: flex;
        flex-direction: column;
        border-radius: 3px;

        h4 {
            text-align: center;
            margin: 0;
            padding: 0;
        }

        &-info {
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;

            .lcolumn,
            .rcolumn {
                width: 49%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 2rem;
                justify-content: space-between;
                background: #ffffff;
                border: 1px solid rgba(128, 128, 128, 0.5);
                box-sizing: border-box;
                border-radius: 4px;
                border-top: none;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
            }

            @if ($form == "profile") {
                .lcolumn {
                    & > div {
                        flex-basis: 49%;
                    }

                    #pt-email {
                        flex-basis: 100%;
                    }
                }
            }

            &-field {
                flex-basis: 100%;
                justify-content: space-between;

                svg {
                    color: lightgray;
                }

                .form {
                    &-label {
                        font-weight: 600;
                    }

                    &-group {
                        select {
                            width: 100%;
                        }

                        input:focus,
                        select:focus {
                            border: 2px solid $mainColor;
                            box-shadow: none;
                        }
                    }
                    &-check-container {
                        display: block;
                        position: relative;
                        padding-left: 25px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;

                        &:hover input ~ .form-check-checkmark {
                            background-color: lightgray;
                        }

                        .form-check-input {
                            &:checked ~ .form-check-checkmark {
                                background-color: $mainColor;
                                border-color: $mainColor;
                                &::after {
                                    display: block;
                                }
                            }
                        }
                        .form-check-checkmark {
                            &:after {
                                left: 0.2rem;
                                top: auto;
                                width: 0.4rem;
                                height: 0.6rem;
                                border: solid white;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }
                    }
                    &-check-input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    &-check-checkmark {
                        position: absolute;
                        top: 0.2rem;
                        left: 0;
                        height: 1rem;
                        width: 1rem;
                        background-color: transparent;
                        border: 2px solid lightgray;

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                    }
                }

                .btn {
                    border-radius: 0px;
                    border: 0px;

                    &-primary {
                        background-color: transparent;
                        border-color: transparent;

                        &:not(:disabled):not(.disabled):active,
                        &:not(:disabled):not(.disabled).active {
                            background-color: transparent;
                        }

                        &.active {
                            background-color: transparent;
                            border-color: transparent;

                            svg {
                                color: $mainColor;
                            }
                        }

                        &.focus {
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                        }
                    }
                }

                .active,
                .focus {
                    background-color: transparent;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .profile-info .lcolumn,
    .experience-info .lcolumn,
    .requirements-info .lcolumn {
        width: 100%;
    }

    .profile-info .rcolumn,
    .experience-info .rcolumn,
    .requirements-info .rcolumn {
        width: 100%;
    }
}

#pt-gender,
#pt-orgTp,
#pt-tpProj {
    flex-basis: 100%;
}

#pt-tpProj > * {
    display: inline-flex;
    flex: 0 0 50%;
    padding: 0;
    margin: 0 0 1rem 0;
}

#pt-compEligbl,
#pt-noCompAvlbl,
#pt-expcProj {
    & > div {
        & > label {
            display: flex;
            margin: 0 0 0.5rem 0;
            padding: 0;
        }
    }
}

#pt-poa,
#pt-org {
    flex-basis: 25%;
}

#genderTypeId {
    display: flex;
    flex-direction: column;

    & > label {
        display: flex;
        padding: 0;
        margin: 0 0 0.5rem 0;
    }
}

#isPOAffiliated {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;

    & > label {
        display: flex;
        padding: 0;
        margin: 0;
    }
}

#diseases-toggle-group,
#patientExperienceId,
//#projectTypeExperiences,
//#projectPreferences,
#organisationPreferences {
    display: flex;
    flex-direction: column;

    & > label {
        display: inline-flex;
        margin: 0 0 1rem 0;
        padding: 0;
    }
}

// #projectPreferences {
//     flex-wrap: wrap;
//     flex-direction: row;
// }

#pt-btns,
#exp-btns {
    width: 100%;
    flex-basis: 100%;
}

#pt-disease-name {
    & > div {
        & > input {
            width: 100%;
        }
    }
}

#pt-gdprm {
    display: flex;
    flex-direction: column;

    & > div {
        & > label {
            display: flex;
        }
    }
}

.menu {
    box-shadow: -8px 12px 11px 9px rgba(0, 0, 0, 0.19);
}

input[role="combobox"] {
    width: 100%;
    height: auto;
    padding: 3px;
}

.fa-check {
    color: black;
    position: absolute;
    top: 10px;
    left: 15.5px;
}

.check-btn-text {
    color: #000;
}

.btn-group,
.btn-group-vertical {
    display: block;
}
