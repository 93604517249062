@import "../../../styles/colors";

.upper-sec {
    width: 100%;
    height: 443px;
    // margin-bottom: 8rem;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(rgba(2, 0, 36, 0.3), rgba(21, 31, 69, 0.2)),
        url("../../../images/upper-section.png");

    &-img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    &-txt {
        display: flex;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-title {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 50px;
            text-align: center;
        }

        &-para {
            color: #fff;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 148.69%;
            padding: 0 20% 0 20%;
            text-align: center;
        }
    }
}

.mid-sec {
    width: 100%;
    height: 32px;
    background: $darkBlue;
}

.lower-sec {
    background: $mainColor;
    // margin-bottom: 8rem;

    .card {
        position: relative;
        bottom: 20%;
        background: #ffffff;
        border: 1px solid #b7bceb;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;

        p {
            margin: 0.5rem 1.5rem 1.5rem 1.5rem;
        }

        &-body {
            padding: 0;
        }

        &-img {
            height: 120px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &-top {
                width: 92px;
                position: relative;
                // height: 7rem;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, 10%);
            }
        }

        &-title {
            font-weight: bold;
            margin: 0.5rem 1.5rem 1.5rem 1.5rem;
        }

        &-text {
            text-align: center;
            height: 6rem;
        }

        button {
            color: $eupatiWhiteColor;
            border-radius: 41px;
            border-color: transparent;
            width: 70%;
            padding: 3%;
        }

        &-r-btn {
            background-color: $navBtnsBgColor;

            &:hover {
                background-color: $secondaryColor;
            }
        }

        &-p-btn {
            background-color: $secondaryColor;

            &:hover {
                background-color: $navBtnsBgColor;
            }
        }

        &.left {
            margin-right: 3rem;
        }

        &-r-btn,
        &-p-btn {
            margin: auto auto 1.8rem auto;
        }
    }
}

.why-title-sec {
    display: flex;
    width: 100%;
    background-color: $lightGreen;
    height: 302px;

    &-txt {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: translate(0, 0);

        &-title {
            color: $darkBlueText;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 50px;
            text-align: center;
        }

        &-para {
            color: #151f45;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 148.69%;
            padding: 0 20% 0 20%;
            text-align: center;
        }
    }
}

.why-content-sec {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;

    &-panel {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
        align-items: center;
        // margin-top: 1rem;
        width: 50rem;

        &-content {
            display: flex;
            flex-direction: column;
            min-width: 50%;

            &-title {
                font-size: 24px; // was 26px
                font-weight: 700;
                color: $darkBlueText;
            }

            &-description {
                font-size: 16px;
                line-height: 1.8rem;
                text-align: justify;
            }
        }

        &-image {
            min-width: 50%;
            padding: 3rem 2rem;
            text-align: center;

            .why-image {
                height: 200px;
                background-position: center;
                background-size: auto;
                background-repeat: no-repeat;
            }

            // .why-image-1 {
            //     background-image: url("../../../images/why-eupati/why-eupati-1.png");
            // }
            // .why-image-2 {
            //     background-image: url("../../../images/why-eupati/why-eupati-2.png");
            // }
        }
    }
}

.note-sec {
    width: 100%;
    background-color: #f2f2f2;
    height: 286px;
    position: relative;

    &-txt {
        display: flex;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50rem;

        &-title {
            color: $darkBlueText;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
        }

        &-para {
            color: #151f45;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 180%;
            padding: 0 10% 0 10%;
            text-align: justify;
        }
    }
}

.stats-sec {
    width: 100%;
    background-color: $darkBlue;
    padding: 50px 0 100px 0;
    position: relative;

    &-txt {
        display: flex;
        position: relative;
        //transform: translate(-50%, -50%);
        // left: 50%;
        // top: 0%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-title {
            color: #ffffff;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
        }
    }

    &-content {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;

        &-stat {
            color: #ffffff;
            text-align: center;
            width: 14%;

            &-title {
                font-size: 24px;
                font-weight: 700;
            }

            &-content {
                font-size: 16px;
            }
        }
    }
}

.engagement-sec {
    width: 100%;
    background: linear-gradient(65.43deg,
            #192954 5.39%,
            #1b518a 23.52%,
            #1c6bae 37.87%,
            #1c75bc 45.43%,
            #2384c1 54.49%,
            #36acce 71.87%,
            #41c3d6 80.93%);
    height: 554px;
    padding: 70px 0 50px 0;
    position: relative;

    &-separator {
        position: absolute;
        left: calc(50% - 40px);
        top: -30px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #ffffff;
    }

    &-txt {
        display: flex;
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 70%;
        margin: auto;

        &-title {
            color: #ffffff;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
        }

        &-para {
            color: #ffffff;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 180%;
            padding: 0 20% 0 20%;
            text-align: center;
        }
    }

    &-content {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &-card {
            width: 230px;
            height: 242px;
            margin: 10px;
            background: #f1f1f0;
            border: 1px solid #b7bceb;
            text-align: center;
            position: relative;
            z-index: 3;

            img {
                position: relative;
                width: 96px;
                height: 96px;
                margin: 30px auto;
            }

            &-name {
                color: $darkBlueText;
                font-size: 18px;
                font-weight: 700;
                margin: 0 auto;
            }
        }

        &-double-card {
            width: 470px;
            max-width: 90%;
            height: 242px;
            margin: 10px;
            background: #f1f1f0;
            border: 1px solid #b7bceb;

            position: relative;
            z-index: 3;

            img {
                position: relative;
                display: inline-block;
                width: 48px;
                height: 48px;
                margin: 16px;
            }

            &-title {
                display: inline-block;
                color: $darkBlueText;
                font-size: 24px;
                font-weight: 700;
                margin: auto;
                width: 100%;
                text-align: center;
            }

            ul {
                display: block;
                padding: 8px 16px;
                list-style-type: none;

                li {
                    position: relative;

                    .fa-check {
                        height: 0.8em;
                        top: 6px;
                        left: 0px;
                        color: $darkBlueText;
                    }
                }
            }

            &-item {
                margin-left: 26px;
                margin-bottom: 4px;

                a {
                    color: #183E78;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;

                    // &:hover {
                    //     text-decoration: none;
                    // }
                }
            }
        }
    }
}

.testimonials-sec {
    width: 100%;
    background-color: #f2f2f2;
    padding: 50px 0 100px 0;
    height: 828px;
    display: inline-block;

    &-txt {
        display: flex;
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-title {
            color: $darkBlueText;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
        }
    }

    &-content {
        display: flex;
        position: relative;
        justify-content: center;
        flex-direction: row;

        &-card {
            width: 50%;
            // height: calc(1100px - 28vw);
            margin: 20px auto;
            background: #ffffff;
            border: 1px solid #b7bceb;
            border-radius: 4px;
            text-align: center;
            padding: 44px 58px;
            z-index: 2;

            img {
                position: relative;
                width: 96px;
                height: 96px;
                margin: 30px auto;
            }

            &-quotes {
                width: 34px;
                height: 30px;
                background-position: center;
                background-size: auto;
                background-repeat: no-repeat;
                background-image: url("../../../images/quotes_icon.png");
            }

            &-text {
                color: $darkBlueText;
                font-size: 16px;
                line-height: 180%;
                margin: 20px auto;
                text-align: left;
                min-height: calc(500px - 25vw);
            }

            &-profile-image {
                width: 156px;
                height: 156px;
                border: solid #e3e6f1 7px;
                border-radius: 50%;
                background-position: center;
                background-repeat: no-repeat;
                margin: auto;

                &.image-joana {
                    background-image: url("../../../images/testimonials/Joana.jpg");
                }

                &.image-kay {
                    background-image: url("../../../images/testimonials/Kay.jpg");
                }

                &.image-paola {
                    background-image: url("../../../images/testimonials/Paola.jpg");
                }

                &.image-ankita {
                    background-image: url("../../../images/testimonials/Ankita.jpg");
                }
            }

            &-profile-name {
                color: $darkBlueText;
                font-weight: bold;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
            }

            &-profile-title {
                color: $secondaryColor;
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
                text-align: center;
            }
        }
    }
}

.faq-sec {
    width: 100%;
    height: 520px;
    padding: 70px 0 50px 0;
    position: relative;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(rgba(2, 0, 36, 0.3), rgba(21, 31, 69, 0.2)),
        url("../../../images/faq/faq_background.png");

    &-separator {
        position: absolute;
        left: calc(50% - 40px);
        top: -30px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #ffffff;
    }

    &-txt {
        display: flex;
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 70%;
        margin: auto;

        &-title {
            color: #ffffff;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
        }

        &-para {
            color: #ffffff;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 180%;
            padding: 0 20% 0 20%;
            text-align: center;
        }

        &-link {
            text-align: center;
            margin: 40px auto 0 auto;
            width: 100%;

            a {
                color: #ffffff;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 180%;
                //padding: 0 20% 0 20%;
                text-decoration: underline;

                &:hover {
                    font-weight: bolder;
                }
            }
        }
    }

    &-content {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &-card {
            width: 455px;
            height: 225px; // was 242px
            margin: 10px;
            background: #f1f1f0;
            border: 1px solid #b7bceb;
            text-align: center;
            position: relative;
            z-index: 3;

            img {
                position: relative;
                width: 87px;
                height: 82px;
                margin: 30px auto;
            }

            &-name {
                color: $darkBlueText;
                font-size: 18px;
                font-weight: 700;
                margin: 0 auto;
            }

            &-text {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.net-ball-partial {
    position: absolute;
    top: 0;
    right: 0;
}

.net-ball-stats-right {
    position: absolute;
    top: 200px;
    right: 0px;
    z-index: 2;
}

.net-ball-stats-left {
    position: absolute;
    top: 160px;
    left: -274px;
    z-index: 2;
}

#home-cards-container {
    margin: 0px;
}

@media screen and (max-width: 1100px) {
    .why-title-sec {
        height: auto;

        &-txt {
            &-title {
                font-size: 32px;
                margin: 1rem auto auto auto !important;
                padding: 0 10%;
            }

            &-para {
                font-size: 20px;
                padding: 1.5rem 15%;
                margin: 0 !important;
                text-align: left;
            }
        }
    }

    .why-content-sec {
        &-panel:nth-child(2n) {
            flex-direction: column-reverse;
        }

        &-txt {
            &-title {
                padding: 1.5 10%;
            }
        }

        &-panel {
            width: 100%;
            height: auto;
            flex-direction: column;

            &-image {
                padding: 0rem 2rem;
            }

            &-content {
                padding: 3rem 15%;

                &-description {
                    text-align: left;
                }
            }
        }
    }

    .note-sec {
        height: auto;

        &-txt {
            width: auto;
            top: 0;
            left: 0;
            transform: translate(0, 0);

            &-title {
                margin: auto !important;
                padding: 1.5rem 15%;
                padding-bottom: 0;
            }

            &-para {
                margin: 0;
                padding: 1.5rem 15%;
                text-align: left;
            }
        }
    }

    .stats-sec {
        &-content {
            flex-wrap: wrap;

            &-stat {
                flex: 1 0 30%;
                width: auto;
                margin: 1rem;
            }
        }
    }

    .engagement-sec {
        height: auto;

        &-content {
            flex-direction: column;
        }
    }

    .testimonials-sec {
        height: auto;

        &-content {
            &-card {
                width: 100%;

                &-text {
                    padding: 1.5rem 15%;
                }

                &-quotes {
                    width: 35%;
                }
            }
        }
    }

    .faq-sec {
        &-content {
            &-card {
                width: auto;
                height: auto;
                top: 0;
                left: 0;
                -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
                padding: 10px 10%;
                padding-bottom: 2rem;
            }
        }
    }

    .net-ball-partial,
    .net-ball-stats-right,
    .net-ball-stats-left {
        display: none;
        //opacity: 0;
    }

    #small-panel {
        align-items: flex-start;
        justify-content: flex-start;

        .why-content-sec {
            &-panel {
                &-image {
                    min-width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 884px) {
    .upper-sec {
        &-txt {
            &-para {
                padding: 0 10% 0 10%;
            }
        }
    }

    #pt-home-btn,
    #res-home-btn {
        margin: 2rem auto 2rem auto;
    }

    #home-cards-container {
        margin: auto -15px auto -15px;
    }
}

@media screen and (max-width: 658px) {
    .lower-sec {
        .card {
            &.left {
                margin-right: 0px;
            }
        }
    }

    .why-title-sec {
        height: auto;

        &-txt {
            &-title {
                font-size: 32px;
                margin: 0px !important;
                padding: 0px;
            }
        }
    }

    .testimonials-sec {
        height: auto;

        &-content {
            &-card {
                width: 100%;

                &-text {
                    padding: 0px;
                }

                &-quotes {
                    width: 15%;
                }
            }
        }
    }
}