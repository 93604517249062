#all-projects-tbl {
    width: 90%;
    margin: auto;

    h4 {
        text-align: center;
        margin: 5rem 2.5rem auto auto;
    }

    th {
        span {
            margin-left: 0.4rem;
        }
    }
}
