@import "../../../styles/colors";

$tabTextColor: #00a4b6;
$lightBlueBg: #afdce6;
$tabSelectedColor: #13a4dd;
$titleTextColor: #183e78;

.d-flex {
    min-height: 120px;
    justify-content: flex-start;
    align-items: center;
}

.pricing-upper-sec {
    width: 100%;
    height: 443px;
    // margin-bottom: 8rem;
    background-position: 50%;
    background-size: cover;
    background-image: url("../../../images/pricing/upper-section.png");

    &-img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    &-txt {
        display: flex;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-title {
            color: $darkBlue;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 50px;
            text-align: center;
        }

        &-sub-title {
            color: $darkBlue;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 148.69%;
            padding: 0 20% 0 20%;
            text-align: center;
            white-space: nowrap;
        }

        &-para {
            color: $darkBlue;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 148.69%;
            padding: 0 20% 0 20%;
            text-align: center;
        }
    }
}

.pricing-lower-sec {
    background-color: #f7f8f8;
    position: relative;
    min-height: 900px;

    #pricing-tabs {
        position: relative;
        top: -25px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .pricing-plan {
        width: 1008px;
        margin: 36px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-card {
            width: 500px;
            background: #ffffff;
            border-radius: 4px;
            padding: 34px 26px 34px 26px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-2 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &-pricebox {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 150px;
                height: 80px;
                border-radius: 2px;
                padding: 0 1rem;
                font-size: 30px;
                line-height: 80px;
                color: $titleTextColor;
                background-color: $lightBlueBg;
                margin-right: 1rem;
            }

            &-pricetitle {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 24px;
                color: $titleTextColor;
                line-height: 40px;

                .title-desc {
                    font-size: 18px;
                }
            }

            &-plantitle {
                font-weight: 600;
                font-size: 30px;
                color: $titleTextColor;
                line-height: 80px;
            }

            &-benefit {
                padding-top: 0.3rem;

                &-title {
                    color: $titleTextColor;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 200%;
                }

                &-description {
                    color: #000000;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            &-submit {
                width: 500px;
                padding: 12px;
                background-color: #fffbf1;
                height: 215px;
                margin: 0 -68px -34px -26px;

                .submit-btn {
                    color: $eupatiWhiteColor;
                    border-radius: 41px;
                    border-color: transparent;
                    width: 70%;
                    padding: 3%;
                    background-color: $secondaryColor;
                    margin: 30px auto;
                    display: block;
                    font-weight: 600;
                    font-size: 22px;

                    &:hover {
                        background-color: $navBtnsBgColor;
                    }
                }

                &-note {
                    font-size: 12px;
                    padding-left: 34px;
                    position: relative;

                    &-asterics {
                        position: absolute;
                        left: 12px;
                        top: 4px;
                    }
                }
            }
        }

        &-basic {
            height: 750px;
        }

        &-tailor-made {
            height: 990px; // was 720px
            padding-bottom: 70px;

            &-options {
                min-height: 390px;
                background-color: #e5f7ff;
                position: relative;

                #options-icon-plus {
                    position: absolute;
                    top: -26px;
                    left: 16px;
                    width: 52px;
                    height: 52px;
                    background: #ffffff;
                    border: 1px solid $tabSelectedColor;
                    border-radius: 50%;
                    font-size: 2rem;
                    line-height: 52px;
                    text-align: center;
                    color: $tabSelectedColor;
                    z-index: 2;
                }

                #options-title-box {
                    position: absolute;
                    top: -19px;
                    left: 42px;
                    width: 269px;
                    height: 38px;
                    background: #ffffff;
                    border: 1px solid $tabSelectedColor;
                    border-radius: 2px;
                    padding-left: 35px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 18px;
                    color: $tabSelectedColor;
                    line-height: 38px;
                }
            }
        }

        &-custom {
            height: 622px;

            .pricing-plan-card-pricebox {
                min-width: 135px;
                height: 80px;
                border-radius: 2px;
                padding: 0 1rem;
                font-size: 30px;
                line-height: 60px;
                color: $titleTextColor;
                background-color: $lightBlueBg;
                margin-right: 1rem;

                .discount {
                    font-size: 20px;
                    line-height: 30px;
                    display: block;
                    margin-top: -20px;
                }
            }
        }
    }
}

.tabs {
    position: relative;
    border-bottom: unset;
    text-align: center;
    margin: auto;
    width: 864px;

    .nav-link {
        width: 280px;
        height: 80px;
        margin: 0 4px;
        border-radius: 4px 4px 0px 0px;
        border-bottom: solid $lightBlueBg 6px;
        color: $tabTextColor;
        font-weight: 600;
        font-size: 26px;
        line-height: 56px;
        background-color: #ffffff;

        &.active {
            border-bottom-color: $tabSelectedColor;
            background-color: $lightBlueBg;
            color: $titleTextColor;
        }

        &:hover {
            border-bottom-color: $tabSelectedColor;
        }
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card {
        border: 0px;
        background-color: transparent;

        &-header {
            background: #13a4dd;
            border-radius: 4px;

            button {
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                color: $eupatiWhiteColor;
                text-decoration: none;
                justify-content: space-between;
                align-items: center;

                &:focus {
                    outline: none !important;
                    box-shadow: none;
                }
            }
        }

        &-body {
            margin: auto auto 0.5rem auto;
            padding: 0;
            border: 1px solid #98ddeb;

            .form-group {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 0px;
                border: 1px solid #98ddeb;

                &:hover {
                    background-color: #daeef2;
                }

                .form-label#cat-price {
                    width: 25%;
                    font-weight: bold;
                    text-align: center;
                    border-right: 1px solid #98ddeb;
                }

                .form-label#cat-name {
                    width: 75%;
                }

                .form-label {
                    margin: 0;
                    padding: 0.5rem;
                    cursor: pointer;
                }
            }
        }
    }
}

.options-section {
    background-color: #e5f7ff;
    color: $eupatiWhiteColor;
    padding: 1rem;

    &-title {
        position: relative;
        bottom: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        background: #ffffff;
        border: 1px solid #13a4dd;
        border-radius: 2px;
        margin-left: 10px;

        label {
            color: #00a4b6;
            padding: 5px;
            margin: 0;
            font-weight: bold;
        }
    }

    &-menu {
        &-item {
            display: flex;
            cursor: pointer;
            margin-left: 3rem;

            :first-child {
                margin-bottom: 1.5rem;
            }

            label {
                color: #183e78;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }

            svg {
                width: 25px !important;
                height: 25px;
                background: #ffffff;
                border: 2px solid #27a5d7;
                border-radius: 2px;
                margin-right: 1.5rem;
            }
        }
    }
}

.payment-check {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#payment-card-header {
    background-color: $eupatiWhiteColor;
    border: 2px solid #00b1bd;

    button {
        color: #13a4dd;
        font-weight: bold;
    }
}

.payments-wrapper {
    display: flex;
    width: 100%;
}

#disabled-option-events {
    pointer-events: none;
    background-color: #dddddd;
    color: #a9a9a9;

    .payment-check {
        svg {
            color: #a9a9a9;
        }
    }
}

#disabled-option-cursor {
    cursor: not-allowed;
}

@media screen and (max-width: 1100px) {
    .pricing-upper-sec {
        height: auto;

        &-txt {
            width: auto;
            height: auto;
            top: 0;
            left: 0;
            transform: translate(0, 0);

            &-title {
                padding: 1.5rem 10%;
                padding-bottom: 0;
                margin: 0 !important;
                font-size: 32px;
            }

            &-sub-title {
                padding: 1.5rem 10%;
                padding-bottom: 0;
                font-size: 20px;
                text-align: left;
                white-space: normal;
            }

            &-para {
                padding: 1.5rem 10%;
                font-size: 18px;
                text-align: left;
                padding-bottom: 3rem;
            }
        }
    }

    .pricing-lower-sec {
        height: auto;
        min-height: auto;

        .tabs.nav.nav-tabs {
            width: auto;
            margin: auto;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .nav-item:last-child() {
                margin: 0;
            }

            .nav-item {
                margin-bottom: 1rem;
            }
        }

        .pricing-plan {
            flex-direction: column;
            justify-content: center;
            align-content: center;
            width: auto;
            margin: 0;
            flex-wrap: nowrap;

            &-card {
                height: auto;
                width: auto;
                padding: 1.5rem 10%;

                &-benefit {
                    &-title {
                        margin-top: 1rem;
                    }
                }

                &-submit {
                    width: auto;
                    height: 100%;
                    margin: 0;
                    padding: 1.5rem 10%;
                    padding-top: 0;

                    &-button {
                        .submit-btn {
                            font-size: 20px;
                            width: auto;
                        }
                    }
                }

                .d-flex {
                    min-height: auto;
                    height: auto;
                    width: auto;
                }

                .accordion {
                    min-height: 300px;
                }
            }
        }
    }

    #pricing-tabs {
        top: 0;
        left: 0;
        transform: translate(0, 0);
    }

    #tailor-made-accordion {
        min-height: 510px;
    }
}

@media screen and (max-width: 600px) {
    .pricing-lower-sec {
        .pricing-plan {
            &-card {
                .accordion {
                    min-height: 350px;
                }
            }
        }
    }

    #tailor-made-accordion {
        min-height: 620px;
    }
}

@media screen and (max-width: 350px) {
    .pricing-lower-sec {
        .pricing-plan {
            &-card {
                .d-flex {
                    flex-direction: column;
                }
            }
        }
    }

    #tailor-made-accordion {
        min-height: 700px;
    }
}