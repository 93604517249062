#privacy,
#faq,
#terms {
    width: 70%;
    margin: 0 auto;
    margin-top: 2rem;

    &-btns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem auto 2rem auto;
    }

    .privacy-content,
    .terms-content,
    .faq-content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        white-space: pre-wrap;

        ul {
            position: relative;
            left: 5%;
        }

        img {
            margin: 0 auto;
        }
    }
}
