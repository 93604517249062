@import "../../../../styles/colors";

.eupati-loader {

  display: flex;
  flex-direction: column;
  align-items: center;
  
  &-circle {
    width: 2.2rem;
    height: 2.2rem;
    border: 0.3125rem solid $quadrantColor;
    border-left-color: $circleColor;
    border-radius: 50%;
    background: transparent;
    animation-name: ROTATE-LOADER;
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    position: relative; 
  }

  &-text {
    font-weight: 400;
  }
}

@keyframes ROTATE-LOADER {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
