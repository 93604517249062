@import "../../../styles/_colors.scss";

$forms: researcher, project, pt;

@each $form in $forms {
    &.#{$form} {
        display: flex;
        flex-direction: column;
        border-radius: 3px;

        h5 {
            text-align: center;
            margin: 0;
            padding: 0;
        }

        &-info {
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;

            .lcolumn,
            .rcolumn {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 2rem;
                justify-content: space-between;
                background: #ffffff;
                border: 1px solid rgba(128, 128, 128, 0.5);
                box-sizing: border-box;
                border-radius: 4px;
                border-top: none;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
            }

            .lcolumn {
                width: 40%;
            }

            @if $form != "pt" {
                div:nth-child(1),
                div:nth-child(2) {
                    flex-basis: 49%;
                }
            } @else {
                .lcolumn,
                .rcolumn {
                    width: 49%;
                }
            }

            &-field {
                flex-basis: 100%;
                justify-content: space-between;

                svg {
                    color: lightgray;
                }

                .form {
                    &-label {
                        font-weight: 600;
                    }

                    &-group {
                        select {
                            width: 100%;
                        }

                        input:focus,
                        select:focus {
                            border: 2px solid $mainColor;
                            box-shadow: none;
                        }
                    }
                    &-check-container {
                        display: block;
                        position: relative;
                        padding-left: 25px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;

                        &:hover input ~ .form-check-checkmark {
                            background-color: lightgray;
                        }

                        .form-check-input {
                            &:checked ~ .form-check-checkmark {
                                background-color: $mainColor;
                                border-color: $mainColor;
                                &::after {
                                    display: block;
                                }
                            }
                        }
                        .form-check-checkmark {
                            &:after {
                                left: 0.2rem;
                                top: auto;
                                width: 0.4rem;
                                height: 0.6rem;
                                border: solid white;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }
                    }
                    &-check-input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    &-check-checkmark {
                        position: absolute;
                        top: 0.2rem;
                        left: 0;
                        height: 1rem;
                        width: 1rem;
                        background-color: transparent;
                        border: 2px solid lightgray;

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                    }
                }

                .btn {
                    border-radius: 0px;
                    border: 0px;

                    &-primary {
                        background-color: transparent;
                        border-color: transparent;

                        &:not(:disabled):not(.disabled):active,
                        &:not(:disabled):not(.disabled).active {
                            background-color: transparent;
                        }

                        &.active {
                            background-color: transparent;
                            border-color: transparent;

                            svg {
                                color: $mainColor;
                            }
                        }

                        &.focus {
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                        }

                        svg {
                            font-size: 20px;
                        }
                    }
                }

                .active,
                .focus {
                    background-color: transparent;
                }

                select:focus {
                    border: 2px solid $mainColor;
                    box-shadow: none;
                }
            }
        }
    }
}

.titles {
    &-wrapper {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
    background-color: $lightGreen;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-bottom: none;

    &-title {
        font-size: 18px;
        text-align: center;
        padding: 5px;

        & > span {
            margin: 0;
            padding: 5px;
        }
    }
}

.extraClass {
    pointer-events: auto !important;

    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }
}

@media screen and (max-width: 767px) {
    .researcher-info .lcolumn,
    .project-info .lcolumn,
    .pt-info .lcolumn {
        width: 100%;
    }

    .researcher-info .rcolumn,
    .project-info .rcolumn,
    .pt-info .rcolumn {
        width: 100%;
    }
}

#p-projAct,
#p-intractnTp,
#p-trvlReimb,
#p-compProv {
    flex-basis: 49%;
}

#p-startDate,
#p-endDate,
#p-resDedln {
    flex-basis: 23%;
}

#projectActivityId,
#interactionTypeId {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > label {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
}

#pocOrganisationTypeId {
    display: flex;

    & > label {
        display: flex;
        padding: 0;
        margin: 0 1rem 0 0;
    }
}

#diseases-toggle-group,
#patientExpertises,
#projectTypeExperiences,
#projectPreferences {
    display: flex;
    flex-wrap: wrap;

    & > label {
        display: inline-flex;
        flex: 0 0 50%;
        padding: 0;
        margin: 0 0 1rem 0;

        & > div {
            clear: both;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

#pt-gdpr {
    display: flex;
    flex-direction: column;

    & > div {
        & > label {
            display: flex;
            padding: 0;
            margin: 0;
        }
    }
}

#pt-ctrys,
#pt-langs,
#pt-diseaseName,
#pt-otherCtry,
#pt-lang,
#pt-disease-name {
    svg {
        cursor: pointer;
        margin-left: 8px;

        &:hover {
            color: $navBtnsBgColor;
        }
    }
}

#r-country,
#r-orgType {
    margin-bottom: 2rem;
}

#r-country {
    label {
        svg {
            cursor: pointer;
            margin-left: 5px;

            &:hover {
                color: $navBtnsBgColor;
            }
        }
    }
}

#r-orgType {
    flex-basis: auto;
}

#languages,
#countries {
    display: flex;
    flex-direction: row;

    & > label {
        display: flex;
        padding: 0;
        margin: 0;
    }
}

#r-disease-name {
    & > div {
        display: flex;

        & > input {
            width: 100%;
        }
    }
}

#r-btns,
#p-btns,
#pt-btns {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;
    padding: 0;
    margin: 0;

    & > button {
        border-radius: 42px;
        padding: 0.5rem;
        width: 7rem;
    }

    .nxt {
        border: 0;
        background-color: $mainColor;

        &:hover {
            background-color: $secondaryColor;
        }
    }

    .bck {
        background-color: $eupatiWhiteColor;
        color: $mainColor;
        border: 2px solid $mainColor;

        &:hover {
            background-color: $secondaryColor;
            color: $eupatiWhiteColor;
            border: 2px solid $secondaryColor;
        }
    }
}

.menu {
    box-shadow: -8px 12px 11px 9px rgba(0, 0, 0, 0.19);
}

input[role="combobox"] {
    width: 100%;
    height: auto;
    padding: 3px;
}

.fa-check {
    color: black;
    position: absolute;
    top: 10px;
    left: 15.5px;
}

.check-btn-text {
    color: #000;
    margin-left: 10px;
}

.btn-group,
.btn-group-vertical {
    display: block;
}

.heading {
    text-align: center;
    margin: 4.5rem 0 3rem 0;
    color: $darkBlue;
}
