#mapping-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & > div {
        flex: 0 0 40%;
    }
}

.mapping-item-wrapper {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
}

.import-pt-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.csv-reader-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > input {
        padding: 10px;
        display: block;
        margin: 15px auto;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
}

hr.solid {
    border-top: 1.5px solid #bbb;
}

#importBtns {
    justify-content: space-evenly;
}
